import { Button } from '@material-ui/core'
import axios from 'axios'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'

import { getAttendanceData } from 'api/attendance'
import { apiEndpoints, getIndianStates } from 'api/common'
import History from 'app/components/generic/history/history'
import Slider from 'app/components/generic/Slider/slider'
import {
  centreScheduleBatchTypes,
  programsMap,
  programTypeList
} from 'app/config'
import { batchTypesMap } from 'app/pages/Class/Inventory/constants'

import Card from './Card'
import * as styles from './styles'

import { attendanceType, dataColumns } from '../config'
import TeacherAttendance from '../TeacherAttendance/TeacherAttendance'
const attendanceTypeExcludeList = ['past', 'future']
const SliderCard = (props) => {
  const {
    value,
    index,
    attendanceChange,
    componentDisabled,
    fetchValues,
    onSpotBookingHandler,
    attendance,
    setAttendance
  } = props

  const handleOnClick = async (event, key) => {
    const response = await getAttendanceData(key)
    setAttendance(response)
  }
  const handleOnClose = () => setAttendance({})

  const page = React.useMemo(() => {
    const data = [
      {
        heading: 'Present',
        value: attendance?.attended_count
      },
      {
        heading: 'Absent',
        value: attendance?.reserved_count - attendance?.attended_count
      },
      {
        heading: 'Cancelled',
        value: attendance?.cancelled_count
      }
    ]

    const attendanceDay =
      programTypeList.includes(value?.batch?.type) &&
      dayjs(value?.date).isBefore(dayjs(), 'day')
        ? 'past'
        : !programTypeList.includes(value?.batch?.type) &&
          dayjs(value?.date).isBefore(dayjs().subtract(2, 'day'), 'day')
        ? 'past'
        : dayjs(value?.date).isAfter(dayjs(), 'day')
        ? 'future'
        : 'present'

    const compareFunc = (a, b) => {
      const first_name = a?.enrolment?.student?.user?.full_name || ''
      const second_name = b?.enrolment?.student?.user?.full_name || ''
      return first_name.localeCompare(second_name)
    }

    const isSlideDisabled = value?.cancelled

    const iscolorenabled = dayjs(value.date).isToday()
      ? dayjs(`${dayjs().format('YYYY-MM-DD')} ${value?.end_time}`).isBefore(
          dayjs(`${dayjs().format('YYYY-MM-DD')} ${dayjs().format('HH:mm')}`),
          'minute'
        )
      : false

    const customComponenthandler = () => {
      if (
        value?.batch?.type === batchTypesMap.exploration &&
        !attendanceTypeExcludeList.includes(attendanceDay)
      ) {
        return (
          <styles.Container>
            <Button
              color="primary"
              variant="contained"
              size={'medium'}
              onClick={onSpotBookingHandler}
              className="button-dashboard">
              on spot enrolment
            </Button>
          </styles.Container>
        )
      } else if (!programsMap.includes(value?.batch?.type)) {
        return (
          <TeacherAttendance
            teacher={value}
            fetchValues={fetchValues}
            attendanceDay={attendanceDay}
          />
        )
      }
    }

    return !isSlideDisabled ? (
      <>
        <Slider
          width="40%"
          key={'SliderCard' + value.uuid}
          placeholder={
            <div onClick={(event) => handleOnClick(event, value.uuid)}>
              <Card
                value={value}
                index={value.uuid}
                iscolorenabled={iscolorenabled}
              />
            </div>
          }
          onClose={handleOnClose}
          childComponent={
            <History
              type={dataColumns[attendanceType[attendanceDay]]?.type}
              heading={dataColumns[attendanceType[attendanceDay]]?.heading}
              userInfo={{}}
              headerInfo={`${value?.batch?.course?.subjects
                ?.map((b) => b.name)
                .join(' ')} | Class 
                      ${value?.batch?.course?.standards
                        ?.map((std) =>
                          std.name.split(' ')[0] === 'Class'
                            ? std.name.split(' ')[1]
                            : std.name
                        )
                        .join(', ')}`}
              data={attendance?.attendances?.sort(compareFunc)}
              tableHeadings={
                dataColumns[attendanceType[attendanceDay]]?.tableHeadings
              }
              accessors={dataColumns[attendanceType[attendanceDay]]?.accessors}
              readOnly={true}
              editableDetails={false}
              addDetails={false}
              headerData={true}
              headers={data}
              customComponentData={true}
              customComponent={customComponenthandler}
              componentChange={attendanceChange}
              componentDisabled={componentDisabled}
            />
          }
        />
      </>
    ) : (
      <Card value={value} index={value.uuid} iscolorenabled={'false'} />
    )
  }, [value, componentDisabled, attendance])

  return <>{page}</>
}

export default SliderCard
