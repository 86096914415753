import { get, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { apiEndpoints, fetchInteractionTopicOptions } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import { navigationPaths, tableColumnDefaultOption } from 'app/config'
import constants from 'app/constants'
import {
  fetchInteractionHistoryData,
  updateInteractionHistoryData
} from 'app/store/actions/communicationEntity'
import { validateNotEmpty, validateNotNil } from 'app/validators'

import { columns } from './config'

import NestedFieldWidget from '../../../components/NestedFieldWIdget'
const InteractionHistory = () => {
  const interactionHistoryData = useSelector(
    (state) => state.communicationEntityReducer.interactionHistoryData
  )
  const [expandedRows, setExpandedRows] = React.useState({})
  const toggleRows = (rowsId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowsId]: !prevExpandedRows[rowsId]
    }))
  }
  const [topicOptions, setTopicOptions] = useState([])
  React.useEffect(() => {
    const fetchOptions = async () => {
      try {
        const optionsResponse = await fetchInteractionTopicOptions()
        const optionsArray = optionsResponse?.results?.[0]?.value
        const optionsObject = optionsArray.reduce((acc, option) => {
          acc[option] = option
          return acc
        }, {})
        setTopicOptions(optionsObject)
      } catch (error) {
        console.error('Error fetching options:', error)
      }
    }
    fetchOptions()
  }, [])

  const modifiedColumns = React.useMemo(() => {
    const updatedColumns = [
      ...columns.slice(0, 1),
      {
        name: 'parent',
        label: 'Parent',
        widget: 'autocomplete',
        minCharactersToSearch: 10,
        modalEdit: true,
        viewOnlyInModal: true,
        defaultValue: () =>
          new URLSearchParams(window.location.search).get('parent'),
        validate: validateNotNil,
        getStructuredValues: (data) => {
          const getItemStructure = (item) => {
            if (Object.prototype.hasOwnProperty.call(item, 'user')) {
              const { uuid, first_name, last_name, phone_number } = item?.user

              return {
                uuid: uuid,
                name: `${phone_number} (${first_name} ${last_name})`
              }
            } else {
              return {
                uuid: item.uuid,
                name: item.name
              }
            }
          }
          if (Array.isArray(data) && !isEmpty(data)) {
            return [getItemStructure(data[0])]
          } else if (Object.prototype.hasOwnProperty.call(data, 'user')) {
            return [getItemStructure(data)]
          } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
            return data.results.map((item) => {
              return getItemStructure(item)
            })
          } else {
            return data.map((item) => {
              return getItemStructure(item)
            })
          }
        },
        getFormDataValue: (data) => {
          const getItemStructure = (item) => {
            if (Object.prototype.hasOwnProperty.call(item, 'user')) {
              return item.user.uuid
            }
          }
          if (Object.prototype.hasOwnProperty.call(data, 'user')) {
            return getItemStructure(data)
          }
        },
        getOptionLabelKey: 'name',
        getOptionSelectedKey: 'uuid',
        resultStructure: {
          keyName: 'parent',
          keyValue: 'uuid'
        },
        apiOptions: {
          method: 'get',
          url: apiEndpoints.parent
        },
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'parent_name',
          customBodyRender: (value) => {
            return value ? (
              <span>
                <a
                  href={`${navigationPaths.parentdashboard}?parent=${value?.user.uuid}`}
                >
                  {value?.user.full_name}
                </a>
              </span>
            ) : null
          }
        }
      },
      ...columns.slice(1, 6),
      {
        name: 'interaction_topics',
        label: 'interaction summary',
        widget: NestedFieldWidget,
        //config contains all fields that are present under interaction_topics
        config: {
          ctaLabel: '+ another topic',
          fields: [
            {
              name: 'topic',
              label: 'topic',
              type: 'select',
              uniqueOption: true, //config option for each select to have a unique option selected
              validate: validateNotEmpty,
              valueLabelMappings: topicOptions
            },
            {
              name: 'summary',
              label: 'summary',
              type: 'text',
              validate: validateNotEmpty
            }
          ]
        },
        disabledInForm: false,
        modalEdit: true,
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          setCellProps: () => ({
            style: {
              minWidth: '400px',
              maxWidth: '400px',
              whiteSpace: 'pre-wrap'
            }
          }),
          customBodyRenderLite: (dataIndex) => {
            const dataArray =
              interactionHistoryData?.data[dataIndex]?.interaction_topics
            const data = dataArray
              .map((topic) => `${topic.topic}: ${topic.summary}. `)
              .join('')

            const truncatedData = data?.slice(0, 100)
            const isTruncated = data?.length > truncatedData?.length
            const isExpanded = expandedRows[dataIndex]
            const displayData = isExpanded ? data : truncatedData

            return (
              <div>
                <span>
                  {!displayData
                    ? 'N/A'
                    : displayData?.length < 100
                    ? displayData
                    : !isExpanded
                    ? displayData + '...'
                    : displayData}
                  <br />
                  {isTruncated && (
                    <div style={{ textAlign: 'right' }}>
                      <a
                        href="#"
                        onClick={() => toggleRows(dataIndex)}
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                      >
                        {isExpanded ? 'show less' : 'show more'}
                      </a>
                    </div>
                  )}
                </span>
              </div>
            )
          }
        },
        conditionalRender: (data) =>
          get(data, 'connected_status') === 'Connected'
      },
      ...columns.slice(6)
    ]
    return updatedColumns
  }, [interactionHistoryData, expandedRows])

  return (
    <>
      <BaseEntity
        entity={constants.INTERACTION_HISTORY}
        label={constants.INTERACTION_HISTORY_LABEL}
        columns={modifiedColumns}
        data={interactionHistoryData}
        fetchData={fetchInteractionHistoryData}
        updateEntityData={updateInteractionHistoryData}
        disableDelete
        disableEditButton
      />
    </>
  )
}

export default InteractionHistory
