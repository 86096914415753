import { isEmpty } from 'lodash'

import { batchTypesMap } from 'app/pages/Class/Inventory/constants'
import { sentryLogger } from 'sentry'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl, modifyQueries } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const enrolmentRequestStructure = (data) => ({
  batch: data.batch,
  payment_mode: data.payment_mode,
  fee: data.fee,
  student: data.student,
  status: data.status,
  type: data.type,
  student_type: data.student_type,
  start_date: data.start_date,
  is_no_fee: data.is_no_fee
})

const fetchEnrolment = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.fetchEnrolment, {
        exclude_type: 'make_up_session',
        batch_type_ne: `${batchTypesMap.exploration},${batchTypesMap.exploration_camp},${batchTypesMap.explore},${batchTypesMap.excel}`,
        ...modifyQueries(queries)
      })

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching enrolments')
    throw error
  }
}

const fetchmakeUp = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.enrolment, {
        no_page: 'True',
        type: 'make_up_session',
        ...modifyQueries(queries)
      })

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching makeups')
    throw error
  }
}

const fetchExploreSessionEnrolments = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.enrolment, {
        no_page: 'True',
        type: 'infinite',
        program_type: `${batchTypesMap.exploration},${batchTypesMap.exploration_camp}`,
        ...modifyQueries(queries)
      })

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching explore session enrolments')
    throw error
  }
}

const fetchExploreEnrolment = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.programEnrolment, {
        program_type: `${batchTypesMap.exploration},${batchTypesMap.exploration_camp}`,
        ...modifyQueries(queries)
      })

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, ' error in fetching explore enrolments')
    throw error
  }
}

const fetchExcelEnrolment = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.programEnrolment, {
        program_type: `${batchTypesMap.excel}`,
        ...modifyQueries(queries)
      })

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, ' error in fetching excel enrolments')
    throw error
  }
}

const createEnrolment = async (data) => {
  try {
    const response = await post(
      apiEndpoints.enrolment,
      enrolmentRequestStructure(data)
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating enrolment')
    throw error
  }
}

const updateEnrolment = async (data) => {
  validateUpdateRequest(data)

  try {
    const queries = enrolmentRequestStructure(data)
    queries.student = queries.student?.user?.uuid

    const response = await patch(
      `${apiEndpoints.enrolment}/${data.uuid}/`,
      queries
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating enrolment')
    throw error
  }
}

const deleteEnrolment = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.enrolment}/${id}`)
      })
    )

    return ids
  } catch (error) {
    sentryLogger(error, ' error in deleting enrolment')
    throw error
  }
}

const changeEnrolmentBatch = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.ohClasses}/change_enrolment_batch`,
      {
        enrolment_uuid: data.enrolment_uuid,
        batch_uuid: data.batch_uuid
      }
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'Error in changing batch')
    throw error
  }
}

const fetchDueAmount = async (data) => {
  const { enrolment, month } = data

  try {
    const response = await get(
      `${apiEndpoints.paymentInvoice}?enrolment=${enrolment}&month=${month}&status=due`
    )

    return response.data
  } catch (error) {
    sentryLogger(error)
    throw error
  }
}

const terminateEnrolment = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.ohClasses}/terminate_enrolment`,
      {
        enrolment_uuid: data.enrolment_uuid,
        amount_to_charge: data.amount_to_charge,
        notes: data.notes,
        reason: data.reason
      }
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'Error in termianting enrolment')
    throw error
  }
}

const getSingleEnrolment = async ({ url, queries, enrolmentId } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.fetchEnrolment}/${enrolmentId}/`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    sentryLogger(error)
    throw error
  }
}

const getEnrolment = async ({ url, queries, batchId } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.batch}/${batchId}/`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in getting enrolment')
    throw error
  }
}

const getSessions = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.attendance}`, { limit: 150, ...queries })

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in getting enrolment')
    throw error
  }
}

const cancelSingleSession = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.attendanceV2}${data.sessionId}/cancel/`,
      { ...data.queries }
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in terminate enrolment')
    throw error
  }
}

const getCoinPrediction = async (data) => {
  try {
    const response = await post(`${apiEndpoints.cart}/calculate/`, {
      suggest: [...data],
      parent_id: data[0].parent,
      book_ongoing: data[0]?.type === 'explore' ? true : false
    })

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in terminate enrolment')
    throw error
  }
}

const getCancelReasons = async () => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.configApp}`, {
    key: 'ATTENDANCE_CANCELLATION_REASONS'
  })

  try {
    const response = await get(requestUrl)

    return response.data.results[0].value
  } catch (error) {
    sentryLogger(error, 'error in terminate enrolment')
    throw error
  }
}

const fetchEnrolmentExtensions = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.actions, {
        ...modifyQueries(queries)
      })

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching enrolment extension details')
    throw error
  }
}
const fetchDeductionReasons = async () => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.configApp}`, {
    key: 'DEDUCTION_REASONS'
  })
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching deduction reasons')
    throw error
  }
}

export {
  cancelSingleSession,
  changeEnrolmentBatch,
  createEnrolment,
  deleteEnrolment,
  fetchDeductionReasons,
  fetchDueAmount,
  fetchEnrolment,
  fetchEnrolmentExtensions,
  fetchExploreEnrolment,
  fetchExcelEnrolment,
  fetchExploreSessionEnrolments,
  fetchmakeUp,
  getCancelReasons,
  getCoinPrediction,
  getEnrolment,
  getSessions,
  getSingleEnrolment,
  terminateEnrolment,
  updateEnrolment
}
